import { Text, StyleProps } from "@chakra-ui/react";
import { FC } from "react";

const Title: FC<
  StyleProps & {
    title: string;
    url?: string | undefined;
    size?: any;
  }
> = ({ title, url, size = "lg", ...styleProps }) => {
  return (
    <Text fontWeight={500} fontSize={size} noOfLines={2} {...styleProps}>
      {title}
    </Text>
  );
};

export default Title;
